body,html{
  background: #1e2347;
}

body {
  width: 100%;
  height: 100vh;
  background: #F2F2F2;
}



.chabag{
  display: block;
  position: relative;
}

.chabag::after{
  content: "";
  background: url('./assets/bg.png');
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}


input#react-select-2-input{
  padding: 0px !important;
  background: transparent !important;
}






/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}









/* MODAL */
.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.modal.modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal > .modal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #5e5e5e;
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  background: rgba(255, 255, 255, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}

.modal > .modal-close:hover {
  background: rgba(255, 255, 255, 0.989);
}

.modal > .modal-body {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: #303030;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 15px 20px;
  color: #c3c0c0;
}


@keyframes fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




input{
  background: #F2F7f8 !important;
  border: 0px !important;
  padding: 10px !important;
}


.plain{
  background: transparent !important;
}




.active{
  background: #fff !important;
  color: #375CA6 !important;
  border-left: 3px solid #fd923a;
}

.icon{
  color: #ffffff;
}

.active .icon{
  color: #090e30
}


.searcher{
  background: #fff !important;
}



/* MEDIA QUERYS */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

  #env-page{
    /*background: gray;*/
  }

  .channel-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
  }

  .channel-icon{
    display: block;
  }

  .channel-label{
    display: none;
  }

  .channel-num{
    display: none;
  }

  #col-bash{
    padding: 0px 20px 0px 20px;
  }

  #col-details{
    padding: 0px 20px 0px 20px;
  }
  
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  #env-page{
    display: grid;
    grid-template-columns: 50px 350px auto;
    grid-template-rows: auto;
    height: 100%;
    /*background: blue;*/
  }

  .channel-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
  }

  .channel-icon{
    display: block;
  }

  .channel-label{
    display: none;
  }

  .channel-num{
    display: none;
  }

  #col-bash{
    padding: 0px 20px 0px 20px;
  }

  #col-details{
    padding: 0px 20px 0px 20px;
  }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  #env-page{
    display: grid;
    grid-template-columns: 260px 350px auto;
    grid-template-rows: auto;
    height: 100%;
    /*background: green;*/
  }

  .channel-item{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
  }

  .channel-icon{
    display: block;
  }

  .channel-label{
    display: block;
  }
  
  .channel-num{
    display: block;
  }

  #col-bash{
    /*padding: 0px 50px 0px 50px;*/
    padding: 0px 20px 0px 20px;
  }

  #col-details{
    padding: 0px 20px 0px 20px;
  }

}







.ImgScreen{
  width: 2000px;
}