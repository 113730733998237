/* examples/custom-styling.css */
.customOverlay {
    background: rgba(255, 255, 255, 0.7);
}

.customModal {
    background: #fff;
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
  }