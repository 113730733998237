
/* MEDIA QUERYS */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    #qr-details{
        display: none;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    #qr-details{
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    #qr-details{
        display: none;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    #qr-details{
        display: none;
    }
}